import { helpers } from '@vuelidate/validators';

let lastPassword;

export default {
  $message: ({ $response }) => $response?.message,
  $validator: async (value) => {
    lastPassword = value;

    // https://github.com/vuelidate/vuelidate/blob/8c1a541ffbf8649a1d13d0da1d9f821a0ac38a5a/packages/validators/src/raw/core.js#L1
    if (!helpers.req(value)) {
      return true;
    }

    if (value.length < 8) {
      return {
        $valid: false,
        message: 'Password must be at least 8 characters.',
      };
    }

    return await new Promise((resolve) => {
      setTimeout(() => {
        if (lastPassword !== value) {
          resolve(true);
          return;
        }

        axios
          .post('/ajax/validators/password', {
            password: value,
          })
          .then(({ data }) => {
            if (!data.valid) {
              resolve({
                $valid: false,
                message: data.message,
              });
            } else {
              resolve(true);
            }
          })
          .catch((err) => {
            resolve({
              $valid: false,
              message: 'Something went wrong.',
            });

            throw err;
          });
      }, 300);
    });
  },
  $async: true,
};
